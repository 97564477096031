import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby';

const GetQuoteTabs = styled.div`
  // 🎛
  width: 100%;
  .GetQuoteTabGrid {
    margin: 0 auto;
    max-width: 1360px;
    // 🎨
    font-family: "Open Sans";
    color: #000;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    // 📰
    display: grid;
    grid-template-rows: 45px;
    grid-template-columns: 160px 160px;
    grid-template-areas:
      "g_ltl_tab g_truckload";

    .LTLTab {
      grid-area: g_ltl_tab; // 🗺
      justify-self: start;

      -webkit-appearance: none;

      color: #0033a1;
      background-color: #f7f7f7;
      font-weight: 700;
      width: 153px;
      height: 40px;
      border: 1px solid rgba(118,130,135,.2);
      border-bottom: none;
      margin-right: 7px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      font-size: 14px;
      text-transform: uppercase;
      position: relative;
      bottom: -3px;
      z-index: 2;
      :focus {
        outline: none;
      }
    }
    .TruckLoad {
      grid-area: g_truckload; // 🗺
      justify-self: start;

      color: #768287;
      background-color: #e6e6e6;

      -webkit-appearance: none;

      font-weight: 700;
      width: 153px!important;
      height: 40px;
      border: 1px solid rgba(118,130,135,.2);
      border-bottom: none;
      margin-right: 7px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      font-size: 14px;
      text-transform: uppercase;
      position: relative;
      bottom: -3px;
      z-index: 1;
      :hover {
        background-color: rgba(230,230,230,.3);
        cursor: pointer;
      }
      :focus {
        outline: none;
      }
    }
  }
`



const EchoLayoutComponent = () => {
  return (

    <GetQuoteTabs>
      <div className="GetQuoteTabGrid">
        <Link to="/quote/GetQuote/">
          <button className="LTLTab">
            LTL
          </button>
        </Link>

        <Link to="/quote/Truckload/">
          <button className="TruckLoad">
            Truckload
          </button>
        </Link>
      </div>
    </GetQuoteTabs>
  )
}

export default EchoLayoutComponent;

import React from 'react'
import styled from 'styled-components'

const SecondaryButton = styled.button`
  font-family: 'Open Sans';
  background-color: 
    ${props => (props.addItem ? 'transparent' :
                props.calculateFreight ? 'transparent' : 
    'transparent')};
  border: 0.1rem solid
    ${props => (props.addItem ? 'transparent' :
                props.calculateFreight ? 'transparent' : 
    'transparent')};
  border-radius: 3px;
  color: 
    ${props => (props.addItem ? '#0033a1' :
                props.calculateFreight ? '#0033a1' : 
    'transparent')};
  cursor: pointer;
  display: inline-block;
  font-size: ${props => (props.calculateFreight ? '14px !important' : '0.875rem')};
  font-weight: 700;
  height: 45px;
  letter-spacing: 0rem;
  padding: 0 1.5rem;
  text-align: center;
  text-decoration: ${props => (props.calculateFreight ? 'underline' : 'none')};
  text-transform: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:focus,
  &:hover {
    opacity: 0.9;
    color:     
    ${props => (props.addItem ? '#fff' :
                props.calculateFreight ? '#0033a1' : 
    '#26c1fb')};
    outline: 0;
    background-color: 
    ${props => (props.addItem ? '#26c1fb' :
                props.calculateFreight ? 'transparent' : 
    '#26c1fb')};
    border: 0.1rem solid   
    ${props => (props.addItem ? '#26c1fb' :
                props.calculateFreight ? 'transparent' : 
    '#26c1fb')};
    text-decoration:     
    ${props => (props.addItem ? 'none' :
                props.calculateFreight ? 'underline' : 
    '#26c1fb')};
  }
`

const Button = ({ addItem, calculateFreight, ...props }) => {
  return (
      <SecondaryButton
          addItem={addItem}
          calculateFreight={calculateFreight}
          {...props}
      />
  )
}
export default Button
import React from "react"
import styled from 'styled-components'

import TypeAheadDestinationZipcode from './Destination/TypeAheadDestinationZipCode.js'
import TypeAheadDestinationSavedLocation from './Destination/TypeAheadDestinationSavedLocation.js'


const ButtonSwitch = styled.button`

  float: right;
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: 400;
  color: #0033a1;
  text-decoration: none;
  transition: 50ms ease, color 50ms ease; 
  -webkit-appearance: none;
  :focus { 
    outline: none; 
  }
  :hover {
    cursor: pointer;
    color: #26C1F1;
  }
`;
class DestinationSwitch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibility: false,
    }
    this.toggleVisibility = this.toggleVisibility.bind(this)
  }

  toggleVisibility() {
    this.setState({
      visibility: !this.state.visibility,
    })
  }

  render() {
    if (this.state.visibility) {
      return (
        <div>
          <ButtonSwitch onClick={this.toggleVisibility}>Search for a Zip Code</ButtonSwitch>
          <div className="form-group es-i-pickup">
            <label
              className="form-label label-lg"
              htmlFor="input-pickup-zipcode"
            >
              Delivery Location <span>*</span>
            </label>
          </div>

          <div className='ui fluid search'>
            <div className='ui icon input'>
              <input onClick={fillInput.bind(this, 'Echo - Long Beach 100 W Broadway, Long Beach, CA 90802')}  placeholder="Search for a Saved Location" type="text" tabindex="0" class="prompt" autocomplete="off" value=""/>
              <i aria-hidden="true" class="search icon"></i>
            </div>
          </div>

          {/*
            <TypeAheadDestinationSavedLocation placeholder="Search for a Saved Location">
          */}
        </div>
      )
    } else {
      return (
        <div>

          <ButtonSwitch onClick={this.toggleVisibility}>Search for a Saved Location</ButtonSwitch>

          <div className="form-group es-i-pickup">
            <label
              className="form-label label-lg"
              htmlFor="input-pickup-zipcode"
            >
              Delivery Zipcode <span>*</span>
            </label>
          </div>

          <div className='ui fluid search'>
            <div className='ui icon input'>
              <input onClick={fillInput.bind(this, '90802')}  placeholder="Search for a Zip Code" type="text" tabindex="0" class="prompt" autocomplete="off" value=""/>
              <i aria-hidden="true" class="search icon"></i>
            </div>
          </div>

          {/*
            <TypeAheadDestinationZipcode placeholder="Search for a Zip Code"></TypeAheadDestinationZipcode>
          */}
          </div>
      )
    }
  }
}

function fillInput( fillVal, event ){
  let targetObj = event.target;
  if( targetObj.value == '' ){
    targetObj.value = fillVal;
  }
}

export default DestinationSwitch
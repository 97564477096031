import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';
import '../styles/icons/css/icons.css';

import 'semantic-ui-css/semantic.min.css'

import OriginSwitch from './Typeahead/OriginSwitch.js'
import InputCalendar from '../InputComponents/InputCalendar.js'

const FunctionalComponentOrigin = styled.div`

  z-index: 1;
  body {
    @import url("https://fonts.googleapis.com/css?family=Teko");
    font-family: "Teko", sans-serif;
  }
  .fa-lock{
  margin-left: -16px;
  padding: 3px 0 3px 3px;
  color: white;
  background-color: #335cb4;
  z-index: 10;
}
  .es-c-container--origin {
    // 🎛
    width: 100%;
    .wrapper {
      margin: 0 auto;
      max-width: 1360px;
      // 🎨
      //
      position: relative;
      z-index: 1;
      font-family: "Open Sans";
      color: #000;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.2px;
      // 📰
      display: grid;
      grid-template-rows: 1fr 1fr 0.2fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        "c_bin_1"
        "c_bin_2"
        "c_set_as_default";

      .es-c-bin-1 {
        grid-area: c_bin_1; // 🗺
        // 🎛
        padding-top: 20px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        border-left: 1px solid rgba(118,130,135,.2);
        border-bottom: 1px solid rgba(118,130,135,.2);
        // 🎨
        background-color: #f7f7f7;
        border-top: 3px solid #0033a1;
        label {
        font-family: "Open Sans" !important;
        font-weight: 600;
        letter-spacing: .3px;
        color: #666;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      input::placeholder {
        color: #555;
        font-size: 14px;
      }
      label, input {
        font-family: sans-serif;
      }
        .es-c-btn.btn-tooltip-2 {
          color: #0033a1;
          text-decoration: none;
          font-size: 12px !important;
          background-color: transparent;
          border: transparent;
        }
      }
      .es-c-bin-2 {
        grid-area: c_bin_2; // 🗺
        // 🎛
        padding-top: 19px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        // 🎨
        background-color: #335cb4;
        border-top: 3px solid #0033a1;
        .es-c-bin-2-wrapper {
          display: grid;
          grid-template-rows: 30px 1fr;
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            "g_pickup_title g_tooltip"
            "g_pickup_select g_options";

          .SgPickupTitle {
            grid-area: g_pickup_title;
            label {
              font-family: "Open Sans";
              font-weight: 600;
            }

          }
          .SgToolTip {
            grid-area: g_tooltip;


            .es-c-btn.btn-tooltip {
              color: #fff;
              text-decoration: underline;
              font-size: 12px !important;
              background-color: transparent;
              border: transparent;
            }
          }
          .SgPickupSelect {
            grid-area: g_pickup_select;
          }
          .SgPickupOptions {
            grid-area: g_options;
            margin-left: 20px;
          }
        }
      }
      .es-c-set-as-default {
        grid-area: c_set_as_default; // 🗺
        // 🎛
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 30px;
        padding-right: 30px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        // 🎨
        background-color: #efefef;
        label {
          color: #141b1f !important;
          font-weight: 400 !important;
          font-size: 14px;
          letter-spacing: 0.3px;
        }
      }
    }
  }

  // Define Responsive Layout Grid - Desktop Spec 🛠
  @media only screen and (min-width: 768px) {
    .es-c-container--origin {
      .wrapper {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 2fr 2fr;
        grid-template-areas:
          "c_bin_1 c_bin_2"
          "c_set_as_default c_set_as_default";

        .es-t-title {
          grid-area: t_title; // 🗺
        }
        .es-c-bin-1 {
          grid-area: c_bin_1; // 🗺
        }
        .es-c-bin-2 {
          grid-area: c_bin_2; // 🗺
        }
        .es-c-set-as-default {
          grid-area: c_set_as_default; // 🗺
          label #text {
            color: #141b1f;
            font-weight: 300;
          }
        }
      }
    }
  }

  // Additional Styles & Overrides specific to component
  .es-c-bin-2 .form-group.es-i-location-type .form-label {
    color: #fff;
  }

    label {
      font-family: "Open Sans";
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      display: block;
      margin-bottom: 5px;
    }

  // Icon Styling
  i {
    color: #141b1f;
  }
  .InputWithIcon input[type="text"] {
  }
  .InputWithIcon {
    position: relative;
  }
  .InputWithIcon i {
    position: absolute;
    right: 12px;
    top: 16px;
  }

  .hide {
    display: none;
  }
`


function selectLocation(e) {
  var filter_name = e.target.value;
  var options = document.querySelectorAll('.origin-option');

  options.forEach(function(el, i){
    if(!el.classList.contains(filter_name) && !el.classList.contains('all')){
      el.classList.toggle('hide', true)
    }else{
      el.classList.toggle('hide', false)
    }
  });
}


const EchoShipFunctionalComponent = () => {

  return (
    <FunctionalComponentOrigin>
      <div className="es-c-container--origin">
        <div className="wrapper">
          <div className="es-c-bin-1">
            <OriginSwitch />

            <div className="form-group es-i-pickup">
              <InputCalendar placeholder="03/10/2019" />
            </div>
          </div>



          <div className="es-c-bin-2">
          <div className="es-c-bin-2-wrapper">

<div className="SgPickupTitle">
<div className="form-group es-i-location-type">
                  <label className="form-label" htmlFor="input-example-1">
                    Pickup Location Type
                  </label>
                  </div>
</div>
<div className="SgPickupSelect">
<div className="form-group es-i-location-type">
                  <select
                    className="form-select select-lg select-location"
                    id="selectLocation_origin"
                    onChange={selectLocation}
                  >
                    <option value="business">Business</option>
                    <option value="residential">Residential</option>
                    <option value="construction">Construction Site</option>
                    <option value="tradeshow">Trade Show</option>
                  </select>
                </div>
</div>

<div className="SgToolTip">
                <button className="es-c-btn btn-tooltip float-right">
                  what's this?
                </button>
</div>
<div className="SgPickupOptions">
                <div className="form-group">
                  <label className="origin-option all">
                    <input type="checkbox" /> Lift-Gate Pickup Required
                  </label>

                  <label className="origin-option all">
                    <input type="checkbox" /> Inside Pickup
                  </label>

                  <label className="origin-option business">
                    <input type="checkbox" /> Limited Access Fee
                  </label>

                  <label className="origin-option all">
                    <input type="checkbox" /> Protect From Freezing
                  </label>

                  <label className="origin-option residential hide">
                    <input type="checkbox" />
                    <i className="fa fa-lock" /> Residential Pickup
                    Fee
                  </label>

                  <label className="origin-option construction hide">
                    <input type="checkbox" />
                    <i className="fa fa-lock" /> Construction Site
                    Pickup
                  </label>

                  <label className=" origin-option tradeshow hide">
                    <input type="checkbox" />
                    <i className="fa fa-lock" /> Tradeshow Pickup
                  </label>

                </div>
</div>

          </div>
          </div>


          <div className="es-c-set-as-default">
            <label>
              <input type="checkbox" /> Set as default origin
            </label>
          </div>
        </div>
      </div>
    </FunctionalComponentOrigin>
  )
}

export default EchoShipFunctionalComponent;

import _ from 'lodash'
import React, { Component } from 'react'
import styled from 'styled-components'
import '../../styles/icons/css/icons.css'
import { Button, Image, List, Transition } from 'semantic-ui-react'
import ModuleAddItem from './_AddItem.js'
// import ButtonRemoveItem from '../../components/Buttons/ButtonRemoveItem.js'

const ButtonAddAnotherItem = styled.button`
  font-family: 'Open Sans';
  background-color: transparent;
  border: transparent;
  border-radius: 3px;
  color: #0033a1;
  cursor: pointer;
  display: inline-block;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  letter-spacing: 0.1rem;
  padding: 0 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:focus,
    &:hover {
      color: #fff;
      outline: 0;
      background-color: #26c1fb;
      border-color: #26c1fb;
      text-decoration: none;
  }
`;
const ButtonRemove = styled.button`
  /* Clear default button styles */
  background-color: transparent;
  color: transparent;
  border: transparent;
  border-radius: 3px;
  -webkit-appearance: none;
  padding: 0px;
  color: #768287;
  cursor: pointer;
  position: relative;
  left: -25px;
  top: -60px;
  z-index: 1;
  float: right;
  font-size: 22px;

  /* display: none; */

    &:hover {
      display: block;
      float: right;
      color: #768287;
      cursor: pointer;
      font-size: 22px;
    }
`;

const users = ['ade', 'chris', 'christian', 'daniel', 'elliot', 'helen']

export default class AddAnotherItem extends Component {
  state = { items: users.slice(0, 0) }

  handleAdd = () => this.setState({ items: users.slice(0, this.state.items.length + 1) })

  handleRemove = () => this.setState({ items: this.state.items.slice(0, -1) })

  render() {
    const { items } = this.state

    return (
      <div>
        <Transition.Group as={List} duration={200} verticalAlign="middle">
          {items.map(item => (
            <List.Item key={item}>
              {/* <Image avatar src={`/images/avatar/small/${item}.jpg`} />
              <List.Content header={_.startCase(item)} /> */}
              <ModuleAddItem />
              <ButtonRemove
                disabled={items.length === 0}
                icon="minus"
                onClick={this.handleRemove}
              >
                <i class="fas fa-times" />
              </ButtonRemove>
            </List.Item>
          ))}
        </Transition.Group>
        <Button.Group>
          <ButtonAddAnotherItem
            disabled={items.length === users.length}
            icon="plus"
            onClick={this.handleAdd}
          >
            <i class="fas fa-plus" /> Add Another Item
          </ButtonAddAnotherItem>
        </Button.Group>
      </div>
    )
  }
}
import React from 'react'
import styled from 'styled-components'

// Import Page Components
import FunctionalComponentOrigin from '../../../FunctionalComponents/FunctionalComponentOrigin.js'
import FunctionalComponentDestination from '../../../FunctionalComponents/FunctionalComponentDestination.js'
import FunctionalComponentAddItems from '../../../FunctionalComponents/FunctionalComponentAddItems.js'
import NavigationGetQuote from '../../../PageComponents/Navigation/Quote/NavigationGetQuote.js'
import GetQuoteTabs from '../../../components/layouts/GetQuoteTabs.js'


const GContentGetQuote = styled.div`
  background: transparent;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  /* grid-template-rows: 250px 250px 250px 150px; */
  grid-template-rows: 39px 300px 250px 1fr 0.5fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "sg_tabs"
    "sg_origin"
    "sg_destination"
    "sg_add_items"
    "sg_navigation_stepper";

  .SGGetQuoteTabs {
    grid-area: sg_tabs; // 🗺
    background: none !important;
  }
  .SGOrigin {
    grid-area: sg_origin; // 🗺
    background: none !important;
    z-index: 1;
  }
  .SGDestination {
    grid-area: sg_destination; // 🗺
    background: none !important;
  }
  .SGAddItems {
    grid-area: sg_add_items; // 🗺
    background: none !important;
  }
  .SGNavigationStepper {
    grid-area: sg_navigation_stepper; // 🗺
    background: none !important;
  }

  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-rows: 450px 450px 450px 150px;
    grid-template-columns: 1fr;
    grid-template-areas:
    "sg_origin"
    "sg_destination"
    "sg_add_items"
    "sg_navigation_stepper";
    
  }
  .ui.modal {
    font-size: 1rem;
    margin: 0 auto;
}
`;
const SGGetQuoteTabs = styled.div`

`;

const SGOrigin = styled.div`
    border-radius: 2px;
`;
const SGDestination = styled.div`

    border-radius: 2px;
`;
const SGAddItems = styled.div`
    border-radius: 2px;
`;
const SGNavigationStepper = styled.div`
    border-radius: 2px;
`;


const EchoShipGridComponentContent = () => {
  return (

    <GContentGetQuote>

      <SGGetQuoteTabs>
        <GetQuoteTabs></GetQuoteTabs>
      </SGGetQuoteTabs>

      <SGOrigin>
        <FunctionalComponentOrigin></FunctionalComponentOrigin>
      </SGOrigin>

      <SGDestination>
      <FunctionalComponentDestination></FunctionalComponentDestination>
      </SGDestination>

      <SGAddItems>
      <FunctionalComponentAddItems></FunctionalComponentAddItems>
      </SGAddItems>

      <SGNavigationStepper>


        <NavigationGetQuote></NavigationGetQuote>
      </SGNavigationStepper>


    </GContentGetQuote>

  )
}

export default EchoShipGridComponentContent;

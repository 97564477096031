import React from "react";
import { Modal } from "semantic-ui-react";
import '../../styles/modal.css';
import '../../styles/icons/css/icons.css'
import styled from "styled-components";

import SecondaryButton from '../../components/Buttons/SecondaryButton.js'

// 💅
const ModalComponentCalculateDensity = styled.div`
  /* border-radius: 4px;
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-shadow: 0 5px 15px rgba(0,0,0,.5); */
  max-width: 960px;
`;

const ModalGrid = styled.div`
  max-width: 960px;
  // 📰
  display: grid;
  grid-template-rows: 0.2fr 1fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "g_modal_header"
    "g_modal_content"
    "g_modal_footer";
`;

// MODAL GRID: HEADER
const ModalHeader = styled.div`
  grid-area: g_modal_header; // 🗺
  min-height: 85px;
  padding-top: 0;
  margin-bottom: 20px;

  border-bottom: 2px solid #e7edf0;
  margin-left: 15px;
  margin-right: 15px;

  .ModalTitle {
    font-family: Teko, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    text-align: center;
    padding-top: 20px;
  }
  .ui.page.modals.dimmer.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal {
    margin: 0 auto !important;
  }
`;

// MODAL GRID: CONTENT
const ModalGridContent = styled.div`
  font-family: "Open Sans";
  grid-area: g_modal_content; // 🗺
  padding-left: 40px;
  padding-right: 40px;
  .es-i-density-calulator {
    display: grid;
    grid-template-rows: 60px 60px 60px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-template-areas:
    "";
  }
`;
// MODAL GRID: CONTENT
const ContentCalculator = styled.div`
    background-color: #e7edf0;
    border-radius: 4px;
    padding: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-template-rows: 60px 30px 60px;
    grid-template-columns: 1fr;
    grid-template-areas:
    "calc_row_1"
    "calc_row_text"
    "calc_row_2";
    .CalculatorRowOne {
      grid-area: calc_row_1; // 🗺
      p {
        font-family: "Open Sans",sans-serif !important;
        font-size: 15px;
        font-weight: 500 !important;
        line-height: 1.42857143;
        color: #141b1f;
      }
    }
    .CalculatorRowTwoText {
      grid-area: calc_row_text; // 🗺
      .CalculatorRowTwoGridText {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas:
        "t_1 t_1 t_1 t_1 t_2 t_2";
        .es-sg-text-1 {
          grid-area: t_1;
        }
        .es-sg-text-2 {
          grid-area: t_2;
        }

        label {
          font-family: "Open Sans";
          font-size: 14px;
        }
      }
    }
    .CalculatorRowTwo {
      grid-area: calc_row_2; // 🗺
      .CalculatorRowTwoGrid {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas:
        "i_1 i_2 i_3 i_4 i_5 i_6";
        .es-sg-input-1 {
          grid-area: i_1;
        }
        .es-sg-input-2 {
          grid-area: i_2;
        }
        .es-sg-input-3 {
          grid-area: i_3;
        }
        .es-sg-input-4 {
          grid-area: i_4;
        }
        .es-sg-input-5 {
          grid-area: i_5;
        }
        .es-sg-input-6 {
          grid-area: i_6;
        }
        label {
          font-family: "Open Sans";
          font-size: 14px;
        }
      }
    }
`;
// MODAL GRID: CONTENT
const ContentReclassNotice = styled.div`
.ReclassNoticeTitle {
  font-size: 24px;
  color: #ffae00;
  margin-bottom: 10px;
}
.ReclassNoticeBox {
  background-color: #fffcef;
  border: 1px solid #f18300;
  border-radius: 4px;
  padding: 20px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 0.05fr 0.9fr;
  grid-template-areas:
  "reclass_icon reclass_text";
  .ReclassNoticeIcon {
    grid-area: reclass_icon;
  }
  .ReclassNoticeText {
    grid-area: reclass_text;
    margin: 0;
    font-family: "Open Sans",sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #141b1f;
    line-height: 20px;
  }
}

`;


// MODAL GRID: FOOTER
const ModalFooter = styled.div`
  grid-area: g_modal_footer; // 🗺
`;

const ModalCalculateDensity = (props) => (
  <Modal trigger={<SecondaryButton calculateFreight>Calculate Freight Class</SecondaryButton>}>

      <ModalComponentCalculateDensity>
      
        <ModalGrid>
          <ModalHeader>
            <div className="ModalTitle">Calculate Item Density</div>
            <div className="Exit" />
          </ModalHeader>

          <ModalGridContent>
            <ContentCalculator>

              <div className="CalculatorRowOne">
                <p>
                  Provide overall measurements of a single truck-ready pallet and hit <br />
                  Calculate to see your recommended freight class.
                </p>
              </div>

              <div className="CalculatorRowTwoText">
              <div className="CalculatorRowTwoGridText">
                <div className="es-sg-text-1">
                  <label
                    className="form-label label-lg bottom"
                    htmlFor="input-height"
                  >
                    Palletted item dimensions
                  </label>
                </div>
                <div className="es-sg-text-2">
                  <label
                    className="form-label label-lg bottom"
                    htmlFor="input-height"
                  >
                    Weight
                  </label>
                </div>
              </div>
              </div>

              <div className="CalculatorRowTwo">

                <div className="CalculatorRowTwoGrid">
                        <div className="es-sg-input-1">
                          <input
                            className="form-input input-lg"
                            type="text"
                            id="input-height"
                            placeholder
                          />
                          <label
                            className="form-label label-lg bottom"
                            htmlFor="input-height"
                          >
                            Length
                          </label>
                        </div>

                        <div className="es-sg-input-2">
                          <input
                            className="form-input input-lg"
                            type="text"
                            id="input-height"
                            placeholder
                          />
                          <label
                            className="form-label label-lg bottom"
                            htmlFor="input-height"
                          >
                            Width
                          </label>
                        </div>

                        <div className="es-sg-input-3">
                          <input
                            className="form-input input-lg"
                            type="text"
                            id="input-height"
                            placeholder
                          />
                          <label
                            className="form-label label-lg bottom"
                            htmlFor="input-height"
                          >
                            Height
                          </label>
                        </div>

                        <div className="es-sg-input-4">
                          <select className="form-select select-lg">
                            <option>IN</option>
                            <option>CM</option>
                          </select>
                        </div>

                        <div className="es-sg-input-5">

                          <input
                            className="form-input input-lg"
                            type="text"
                            id="input-height"
                            placeholder
                          />

                        </div>

                        <div className="es-sg-input-6">
                          <select className="form-select select-lg">
                            <option>LBS</option>
                            <option>KG</option>
                          </select>
                        </div>

                </div>
              </div>



            </ContentCalculator>

            <ContentReclassNotice>
                <div className="ReclassNoticeTitle">
                  Reclass Notice
                </div>
                <div className="ReclassNoticeBox">
                <div className="ReclassIcon">
                <i class="fas fa-exclamation-circle"></i>
                </div>
                  <div className="ReclassNoticeText">
                    <strong>Disclaimer about NMFC Standards for Freight Classes:</strong> The Class number provided in the Echo Cube/Density Calculator is only an estimate and does not take into account the commodity. Moreover, this calculator does not factor in possible liability or handling characteristics of the commodity. Due to these limitations, items shipped that are not classed solely based on density may be more likely to have an incorrect classification.
                  </div>
                </div>
            </ContentReclassNotice>

          </ModalGridContent>

          <ModalFooter />
        </ModalGrid>
      </ModalComponentCalculateDensity>

  </Modal>
);
export default ModalCalculateDensity;
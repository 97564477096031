import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';
import '../styles/icons/css/icons.css';

import DestinationSwitch from './Typeahead/DestinationSwitch.js'
// import EchoTypeAhead from '../FunctionalComponents/Typeahead/EchoTypeAheadComponent.js'

const FunctionalComponentDestination = styled.div`

// Container Component - Destination 📦
// Define Responsive Layout Grid - Mobile First Approach 🛠
.fa-lock{
  margin-left: -16px;
  padding: 3px 0 3px 3px;
  color: white;
  background-color: #335cb4;
  z-index: 10;
}
.es-c-container--destination {
  // 🎛
  width: 100%;
  .wrapper {
  margin: 0 auto;
  max-width: 1360px;
  // 🎨
  font-family: 'Open Sans';
  color: #000;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  // 📰
  display: grid;
  grid-template-rows: 1fr 1fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "c_bin_1"
    "c_bin_2"
    "c_set_as_default";

  .es-c-bin-1 {
    grid-area: c_bin_1; // 🗺
    // 🎛
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    // 🎨
    background-color: #f7f7f7;
    border-top: 3px solid #0033a1;
    border-left: 1px solid rgba(118,130,135,.2);
    border-bottom: 1px solid rgba(118,130,135,.2);
    label {
      font-family: "Open Sans" !important;
      font-weight: 600;
      letter-spacing: .3px;
      color: #666;
    }
    input::placeholder {
      color: #555;
      font-size: 14px;
    }
    label, input {
      font-family: sans-serif;
    }
    .es-c-btn.btn-tooltip-2 {
      color: #0033a1;
      text-decoration: none;
      font-size: 12px !important;
      background-color: transparent;
      border: transparent;
    }
  }
  .es-c-bin-2 {
    grid-area: c_bin_2; // 🗺
    // 🎛
    padding-top: 19px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    // 🎨
    background-color: #335cb4;
    border-top: 3px solid #0033a1;
    .es-c-bin-2-wrapper {
      display: grid;
      grid-template-rows: 30px 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "g_pickup_title g_tooltip"
        "g_pickup_select g_options";

      .SgPickupTitle {
        grid-area: g_pickup_title;
        label {
          font-family: "Open Sans";
          font-weight: 600;
        }
      }
      .SgToolTip {
        grid-area: g_tooltip;

        .es-c-btn.btn-tooltip {
          color: #fff;
          text-decoration: underline;
          font-size: 12px !important;
          background-color: transparent;
          border: transparent;
        }
      }
      .SgPickupSelect {
        grid-area: g_pickup_select;
      }
      .SgPickupOptions {
        grid-area: g_options;
        margin-left: 20px;
      }
    }
  }
  .es-c-set-as-default {
    grid-area: c_set_as_default; // 🗺
    // 🎛
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    // 🎨
    background-color: #efefef;
    label {
      color: #141b1f !important;
      font-weight: 400 !important;
      font-size: 14px;
      letter-spacing: .3px;
    }
  }
}
}

// Define Responsive Layout Grid - Desktop Spec 🛠
@media only screen and (min-width: 768px) {
  .es-c-container--destination {
    width: 100%;
    .wrapper {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 2fr;
    grid-template-areas:
      "c_bin_1 c_bin_2"
      "c_set_as_default c_set_as_default";
    
    .es-c-bin-1 {
      grid-area: c_bin_1; // 🗺
    }
    .es-c-bin-2 {
      grid-area: c_bin_2; // 🗺
    }
    .es-c-set-as-default {
      grid-area: c_set_as_default; // 🗺
    }
  }
}
}

// Additional Styles & Overrides specific to component
.es-c-bin-2 .form-group.es-i-location-type .form-label {
  color: #fff;
}

label {
  font-family: "Open Sans";
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
  }

// Icon Styling
i {
  color: #141b1f;
}
.InputWithIcon input[type=text] {

}
.InputWithIcon {
  position: relative;
}
.InputWithIcon i{
  position: absolute;
  right: 12px;
  top: 16px;
}
.hide {
  display: none;
}
`;

function selectLocation(e) {
  var filter_name = e.target.value;
  var options = document.querySelectorAll('.destination-option');

  options.forEach(function(el, i){
    if(!el.classList.contains(filter_name) && !el.classList.contains('all')){
      el.classList.toggle('hide', true)
    }else{
      el.classList.toggle('hide', false)
    }
  });
}


const EchoShipFunctionalComponent = () => {
  
  return (
    <FunctionalComponentDestination>
      <div className="es-c-container--destination">
        <div className="wrapper">
          <div className="es-c-bin-1">
            <DestinationSwitch></DestinationSwitch>
          </div>



          <div className="es-c-bin-2">
          <div className="es-c-bin-2-wrapper">

<div className="SgPickupTitle">
  <div className="form-group es-i-location-type">
                <div className="form-group es-i-location-type">
                  <label className="form-label" htmlFor="input-example-1">
                    Delivery Location Type
                  </label>
                </div>
  </div>
</div>

<div className="SgPickupSelect">
<div className="form-group es-i-location-type">
                  <select className="form-select select-lg select-location" id="selectLocation_destination" onChange={selectLocation}>
                    <option value="business">Business</option>
                    <option value="residential">Residential</option>
                    <option value="construction">Construction Site</option>
                    <option value="tradeshow">Trade Show</option>
                  </select>
                </div>
</div>
<div className="SgToolTip">
                <button className="es-c-btn btn-tooltip float-right">
                  what's this?
                </button>
</div>
<div className="SgPickupOptions">
                <div className="form-group">
                  <label className="destination-option all">
                    <input type="checkbox" /> Inside Delivery
                  </label>
                  <label className="destination-option all">
                    <input type="checkbox" /> Notify Prior To Delivery
                  </label>

                  <label className="destination-option all">
                    <input type="checkbox" /> Lift-Gate Delivery Required
                  </label>

                  <label className="destination-option residential hide">
                    <input type="checkbox" />
                    <i className="fa fa-lock"/> Residential Pickup Fee
                  </label>

                  <label className="destination-option business">
                    <input type="checkbox" /> Limited Access Fee
                  </label>

                  <label className="destination-option business construction">
                    <input type="checkbox" /> Sort And Segregate
                  </label>

                  <label className="destination-option construction hide">
                    <input type="checkbox" />
                    <i className="fa fa-lock" /> Construction Site Pickup
                  </label>
                  <label className="destination-option tradeshow hide">
                    <input type="checkbox" />
                    <i className="fa fa-lock" /> Tradeshow Pickup
                  </label>
            </div>
          </div>
        </div>
      </div>

          <div className="es-c-set-as-default">
            <label>
              <input type="checkbox" /> Set as default origin
            </label>
          </div>
        </div>
      </div>
    </FunctionalComponentDestination>
  )
}

export default EchoShipFunctionalComponent;
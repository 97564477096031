import React from 'react'
import styled from 'styled-components'
import '../../styles/spectre.css';
import '../../styles/icons/css/icons.css';


import ModalCalculateDensity from '../../FunctionalComponents/Modal/ModalCalculateDensity.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
library.add(faExclamationTriangle)


const ModuleAddItem = styled.div`
    width: 100%;
.add-items-module {
    width: 100%;

    .add-items-wrapper {
      // 🎛
      margin: 0 auto;
      max-width: 1360px;
      // 📰
      display: grid;
      grid-template-rows:  1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        "sg_forms";



  .es-sg-wrap-row-2 {
    grid-area: sg_forms; // 🗺

    .es-sg-row-2 {
      background-color: #fff;
      border: 1px solid #fff;
      padding-left: 12px;
      padding-top: 12px;
      transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);

      &:hover {
        background-color: #E8EDF0;
        border: 1px solid #BABBBB;
        padding-left: 12px;
        padding-top: 12px;
      }


      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 40px 1fr 1fr 1fr 2fr;
      grid-template-areas: "i_1 i_2 i_3 i_4 i_5 i_5 i_5 i_6";

      .input-1 {
        grid-area: i_1; // 🗺
        // 🎨
      }

      .input-2 {
        grid-area: i_2; // 🗺
        // 🎨
      }

      .input-3 {
        grid-area: i_3; // 🗺
        // 🎨
      }

      .input-4 {
        grid-area: i_4; // 🗺
        // 🎨
      }

      .input-5 {
        grid-area: i_5; // 🗺
        // 🎨
      }

      .input-6 {
        grid-area: i_6; // 🗺
        // 🎨
        align-self: center;
        justify-self: start;
        padding-bottom: 32px;
        margin-left: 35px;
        font-size: 14px;
        color: gray;
        display: inline;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }
    }
  }




// Define Responsive Layout Grid - Mobile Spec 🛠
@media only screen and (max-width: 768px) {
  .es-c-container--destination {
    width: 100%;
    .wrapper {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr 2fr;
    grid-template-areas:
      "t_title c_bin_1 c_bin_2"
      ". c_set_as_default c_set_as_default";
    
    .es-t-title {
      grid-area: t_title; // 🗺
    }
    .es-c-bin-1 {
      grid-area: c_bin_1; // 🗺
    }
    .es-c-bin-2 {
      grid-area: c_bin_2; // 🗺
    }
    .es-c-set-as-default {
      grid-area: c_set_as_default; // 🗺
    }
  }
}
}

// Additional Styles & Overrides specific to component
.es-c-bin-2 .form-group.es-i-location-type .form-label {
  color: #fff;
}
.es-c-bin-2 .form-group {
  padding-top: 40px;
  padding-left: 18px;

  .form-checkbox {
    color: #fff;
  }
}


.es-i-group--handling-unit-dimensions {
  .form-group.es-i-handling-unit-dimensions {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
    "g_input_group";
    
      .es-g-input-group {
        grid-area: g_input_group; // 🗺
        // 🎨
        .grid-wrapper {
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 55px 0.3fr 10px 0.3fr 55px;
          grid-template-areas:
          "sg_1 sg_2 sg_3 sg_4 sg_5";
          
            .es-sg-1 {
              grid-area: sg_1; // 🗺
              // 🎨
            }
            .es-sg-2 {
              grid-area: sg_2; // 🗺
              align-self: center;
              // 🎨
              padding-bottom: 32px;
              margin-left: 20px;
              margin-right: 20px;
              font-weight: 600;
              color: #666;
            }
            .es-sg-3 {
              grid-area: sg_3; // 🗺
              // 🎨
            }
            .es-sg-4 {
              grid-area: sg_4; // 🗺
              align-self: center;
              // 🎨
              padding-bottom: 32px;

              font-weight: 600;
              color: #666;
            }
            .es-sg-5 {
              grid-area: sg_5; // 🗺
              // 🎨
            }
          form.input {
            width: auto;
          }
        }
      }
  }
}


@media only screen and (max-width: 768px) {
.es-c-container--items {
.grid-wrapper {
  // 🎛
  margin: 0 auto;
  max-width: 1360px;
  // 📰
  display: grid;
  grid-template-rows: 0.2fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "g_title"
    "g_bin";
  }
  .es-g-title {
    grid-area: g_title; // 🗺
  }
  .es-g-title {
    grid-area: g_bin; // 🗺
  }
}
}


@media only screen and (max-width: 868px) {
  .es-c-container--items
  .grid-wrapper
  .es-g-bin
  .bin-sg 
  .es-sg-wrap-row-2
  .es-sg-row-2 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    grid-template-areas: 
      "i_1 i_2 i_3 i_4"
      "i_5 i_5 i_5 i_6";

    .input-1 {
      grid-area: i_1; // 🗺
    }
    .input-2 {
      grid-area: i_2; // 🗺
    }
    .input-3 {
      grid-area: i_3; // 🗺
    }
    .input-4 {
      grid-area: i_4; // 🗺
    }
    .input-5 {
      grid-area: i_5; // 🗺
    }
    .input-6 {
      grid-area: i_6; // 🗺
    }
  }
}
`;




const EchoShipFunctionalComponent = ({children}) => {
  return (
    <ModuleAddItem>
    <div className="add-items-module">
    <div className="add-items-wrapper">
              <div className="es-sg-wrap-row-2">
                <div className="es-sg-row-2">
                  <div className="input-1">
            
                    <div className="form-group es-i-pickup">
                        <input
                          className="form-input input-lg"
                          type="text"
                          id="input-pickup-zipcode"
                          placeholder=""
                        />
                    </div>
              
                  </div>
                  <div className="input-2">
                 
                    <div className="form-group es-i-unit">
                      <select className="form-select select-lg">
                        <option>LBS</option>
                        <option>KG</option>
                      </select>
                    </div>
               
                  </div>
                  <div className="input-3">
                  
                    <div className="form-group es-i-freight-class">
                      <select className="form-select select-lg">
                        <option>50</option>
                        <option>55</option>
                        <option>60</option>
                        <option>65</option>
                        <option>70</option>
                        <option>77.5</option>
                        <option>85</option>
                        <option>92.5</option>
                        <option>100</option>
                        <option>110</option>
                        <option>125</option>
                        <option>150</option>
                        <option>175</option>
                        <option>200</option>
                        <option>250</option>
                        <option>300</option>
                        <option>400</option>
                        <option>500</option>
                      </select>
                    </div>
            
                  </div>
                  <div className="input-4">
             
                      <ModalCalculateDensity>    
                        {children}
                      </ModalCalculateDensity>

                  </div>
                  <div className="input-5">
  
                    <div className="es-i-group--handling-unit-dimensions">
                      <div className="form-group es-i-handling-unit-dimensions">
                        <div className="es-g-input-group">
                          <div className="grid-wrapper">
                            <div className="es-sg-1">
                              <input
                                className="form-input input-lg"
                                type="text"
                                id="input-length"
                                placeholder
                              />
                              <label
                                className="form-label label-lg bottom"
                                htmlFor="input-length"
                              >
                                Length
                              </label>
                            </div>
                            <div className="es-sg-2">
                              <div className="seperator">X</div>
                            </div>
                            <div className="es-sg-3">
                              <input
                                className="form-input input-lg"
                                type="text"
                                id="input-width"
                                placeholder
                              />
                              <label
                                className="form-label label-lg bottom"
                                htmlFor="input-width"
                              >
                                Width
                              </label>
                            </div>
                            <div className="es-sg-4">
                              <div className="seperator">X</div>
                            </div>
                            <div className="es-sg-5">
                              <input
                                className="form-input input-lg"
                                type="text"
                                id="input-height"
                                placeholder
                              />
                              <label
                                className="form-label label-lg bottom"
                                htmlFor="input-height"
                              >
                                Height
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-6">
                    <div className="form-group es-i-sort-by">
                      <label>
                        <input type="checkbox" />
                        <FontAwesomeIcon icon="exclamation-triangle" style={{ color: '#000' }} /> Hazardous Material
                      </label>
                      
                    </div>
                  </div>

                </div>
              </div>


</div>
</div>


    </ModuleAddItem>
  )
}

export default EchoShipFunctionalComponent;